import { useEffect, useState } from "react"

interface Job {
    id: string
    attributes: {
        title: string
        "created-at": string
        pitch: string // Assuming you want to display this too
    }
    relationships: {
        department: {
            data: { id: string; type: string } | null
        }
        locations: {
            data: { id: string; type: string }[] // Multiple locations
        }
    }
    links: {
        "careersite-job-url": string // Add this line to include the job URL
    }
}

interface Department {
    id: string
    attributes: {
        name: string
    }
}

interface Location {
    id: string
    attributes: {
        name: string
    }
}

interface ApiResponse {
    data: Job[]
    included: (Department | Location)[]
}

export function JobListings() {
    const [jobs, setJobs] = useState<Job[]>([])
    const [departments, setDepartments] = useState<Record<string, string>>({})
    const [locations, setLocations] = useState<Record<string, string>>({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchJobs() {
            try {
                const response = await fetch(
                    "https://api.teamtailor.com/v1/jobs?include=department,locations",
                    {
                        method: "GET",
                        headers: {
                            Authorization:
                                "Token token=kboOp02UCu_ZiQoOlstuKTXpWHi0n-wntcWBfVEH",
                            "X-Api-Version": "20240404",
                            Accept: "application/vnd.api+json",
                        },
                    }
                )
                const result: ApiResponse = await response.json()

                // Create maps for departments and locations
                const departmentsMap = result.included
                    .filter(
                        (item): item is Department =>
                            item.attributes.name !== undefined
                    )
                    .reduce(
                        (acc, department) => {
                            acc[department.id] = department.attributes.name
                            return acc
                        },
                        {} as Record<string, string>
                    )

                const locationsMap = result.included
                    .filter(
                        (item): item is Location =>
                            item.attributes.name !== undefined
                    )
                    .reduce(
                        (acc, location) => {
                            acc[location.id] = location.attributes.name
                            return acc
                        },
                        {} as Record<string, string>
                    )

                setDepartments(departmentsMap)
                setLocations(locationsMap)
                setJobs(result.data)
                setLoading(false)
            } catch (error) {
                console.error("Error fetching jobs:", error)
                setLoading(false)
            }
        }

        fetchJobs()
    }, [])

    function formatDate(dateString: string): string {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
            return "Invalid Date"
        }
        return date.toLocaleDateString() // Customize the date format as needed
    }

    return (
        <>
            <style>
                {`
                    :root {
                        --itcan-navy: #1F1646;
                        --itcan-red: #E40246;
                    }
                    .job-listings {
                        font-family: Altone, sans-serif;
                        color: var(--itcan-navy);
                        padding: 20px;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                        gap: 20px;
                        width: 100%;
                    }
                    .job-card {
                        text-decoration: none;
                        color: inherit;
                        display: block;
                        border: 1px solid #ddd;
                        border-radius: 8px;
                        padding: 20px;
                        background-color: #fff;
                        font-family: Altone, sans-serif;
                        transition: transform 0.2s ease-in-out;
                    }
                    .job-card:hover {
                        transform: translateY(-5px);
                    }
                    .job-card h2 {
                        margin: 0 0 20px;
                        color: var(--itcan-navy);
                        transition: color 0.2s ease-in-out;
                    }
                    .job-card:hover h2 {
                        color: var(--itcan-red);
                    }
                `}
            </style>
            <div className="job-listings">
                {loading ? (
                    <div style={{ padding: 20 }}>Loading...</div>
                ) : !jobs.length ? (
                    <div style={{ padding: 20 }}>
                        No job listings available.
                    </div>
                ) : (
                    jobs.map((job) => (
                        <a
                            key={job.id}
                            href={job.links["careersite-job-url"]}
                            className="job-card"
                            target="_blank"
                            rel="noopener noreferrer" // Optional for security
                        >
                            <div>
                                <h2>{job.attributes.title}</h2>
                                <p>
                                    <strong>Location(s): </strong>
                                    {job.relationships.locations.data
                                        .map(
                                            (loc) =>
                                                locations[loc.id] || "Unknown"
                                        )
                                        .join(", ")}
                                </p>
                                <p>
                                    <strong>Department: </strong>
                                    {job.relationships.department.data
                                        ? departments[
                                              job.relationships.department.data
                                                  .id
                                          ] || "Unknown"
                                        : "Not Assigned"}
                                </p>
                                <p>
                                    <strong>Posted on:</strong>{" "}
                                    {formatDate(job.attributes["created-at"])}
                                </p>
                            </div>
                        </a>
                    ))
                )}
            </div>
        </>
    )
}
